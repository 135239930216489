@import "./bootstrap/_variables.scss";
@import "_variables";
@import "./bootstrap/mixins/_forms.scss";
@import "_Media-queries";
@import "_Flexbox";

.sortable--container {
    @include flexbox;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;

    .sort-handle {
        cursor: pointer;
    }
    // Styline for parent sortable group
    .panel-heading & {
    }
    // Styling for sortable element that is a child of the group above
    /*.panel-body & {
        padding: 6px 12px;
        box-shadow: 0 1px 4px 0 rgba(0,0,0,0.20);
        border-radius: 4px;
        height: 60px;
        max-height: 60px;
        background: #FFF
    }*/
    .input-group.sortable--input .input-group-addon {
        background: $input-bg;
        padding-right: 2px;
    }

    .input-group.sortable--input .form-control {
        border-left: 0 !important;
        padding-left: 0;
    }

    .input-group.sortable--input .form-control:focus {
        border-left: 2px;
        border-color: #FFF;
        -webkit-box-shadow: unset;
        box-shadow: unset;
    }

    .sortable--label.form-control {
        border: none;
        background: none;
        box-shadow: none;

        &:hover {
            border-color: $input-border;
            @include box-shadow(inset 0 1px 1px rgba(0,0,0,.075)); // Redeclare so transitions work
        }

        &:focus {
            border: 1px solid $input-border;
            $shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 6px lighten($input-border, 20%);
            @include box-shadow($shadow);
            background: #FFF;
        }
    }

    .sortable--handle {
        margin-left: -10px;
        flex: 0 0 auto;
    }

    .sortable--input {
        margin-right: 15px;
        width: 100px;
        flex: 0 0 100px;
    }
    // Sortable label name will group/shrink to fill up the available space available
    // Styling allow long word to be broken up and text to wrap to 2 lines
    .sortable--label {
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word;
        max-height: 40px;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 100%;
        flex: 1 1 auto;
    }
}


.nav-tabs > li >a {
    background: $gray-lighter;
}
// Configuration options available from Sortable library
// https://github.com/RubaXa/Sortable#options

// Class name for the placeholder (showing where items will be dropped)
.sortable-ghost > .sortable--container,
.sortable-ghost.product--container--fixed {
    -webkit-filter: brightness(90%) blur(2px);
    filter: brightness(90%) blur(2px);
    background: $gray-lighter;
}

// Class name for the dragged items
.sortable-drag {
    opacity: 1;
  }
.sortable-drag > .sortable--container,
.sortable-drag.product--container--fixed {
    box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.3);
    opacity: 1;
    transition: opacity .25s ease-in-out;
}

