.nav-tabs > li > a.btn.btn-primary {
    @include button-variant($btn-primary-color, $btn-primary-bg, $btn-primary-border);
}

.nav-tabs > li > a.btn.btn-success {
    @include button-variant($btn-success-color, $btn-success-bg, $btn-success-border);
}

.nav-tabs > li > a.btn.btn-link {
    background: #FFF;
    border: #DDD dashed 2px;
    border-bottom: 0;
    color: #667479;
}


.nav-tabs > li > a.btn.btn-link {
    background: #FFF;
    border: #DDD dashed 2px;
    border-bottom: 0;
    color: #667479;
}

.nav-tabs > li > a.btn.btn-link.text-only {
    font-size: 19px;
}