.logo-chooser__gallery {
  overflow-x: scroll;
}

.logo-chooser__gallery__scroll {
  width: max-content;
}

.logo-chooser__logo {
  width: 200px;
  height: 200px;
  padding: 10px;
  margin: 15px;
  background-color: $gray-light;
  border: $gray-light 5px solid;
  position: relative;
  float: left;

  &--selected {
    border-color: $brand-success
  }
}

.logo-chooser__logo__image {
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.logo-chooser__logo__delete {
  position: absolute;
  top: -15;
  right: -15;
  font-size: 20;
  &:hover {
    color: red;
  }
}

.logo-chooser__info {
  padding: 10px;
}
