@mixin flexbox($direction: row) {
  display: flex;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  flex-direction: $direction;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.list {
    display: flex;
    flex-wrap: wrap;
}

.list-item {
    display: flex;
    padding: 0.5em;
}



.list-content {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    padding: 1em;
    width: 100%;
}

.list-content .content {
    flex: 1 0 auto;
}