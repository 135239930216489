nav.nav{
    height: 100%;
    position: fixed;
    z-index: 2;
    width: 200px;
    overflow: auto;

    @include  gradient-directional($start-color: $sidebar-gradient-start, $end-color: $sidebar-gradient-end, $deg: $sidebar-gradient-degree);
    color: #FFF;

    .head {
        @include  gradient-directional($start-color: $sidebar-head-gradient-start, $end-color: $sidebar-head-gradient-end, $deg: $sidebar-head-gradient-degree);
        padding: 15px;
        font-weight: 800;
    }

    .section {
        padding: 20px 0;
        .panel-title {
            a {
                color: #000;
                padding: 0;
            }
        }
    }

    .subheader {
        padding: 0 20px;
        display: block;
        letter-spacing: 1px;
    }

    a {
        padding: 5px 20px;
        cursor: pointer;
        display: block;
        color: #fff;
        margin-bottom: 5px;

    }

    a.disabled {
        padding: 5px 20px;
        cursor: pointer;
        display: block;
        color: #fff;
        opacity: 0.5;
    }

    a:hover, a:focus {
        text-decoration: none;
        background: rgba(0,0,0,.20);
    }

    a.active {
        padding: 5px 20px;
        cursor: pointer;
        background: $sidebar-selected-color;
        color: #fff;
    }

    &.collapsed {
        margin-left: -200px;
    }
}  


nav.nav, .main {
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}


