.grid-container {
  display: grid;
  grid-template-columns: 1fr 200px minmax(150px, 1fr) 50px;
}

.grid-row {
  display: contents; /* Keeps the row layout intact */
}

.grid-item {
  margin-top: 20px;
  white-space: nowrap; /* Prevent wrapping */
}
