@import './_variables.scss';

@import './_bootstrap.scss';
@import './_bootswatch.scss';
@import './bootstrap/mixins/_gradients.scss';

@import './iconaddon.scss';

@import './dataTables.bootstrap.scss';

@import './_progressbar.scss';

@import './_autoclear.scss';

@import './_Sortable.scss';
@import './grid.scss';
@import './_recursivePanels.scss';
@import './forms.scss';
@import './_tab.scss';

@import './Button.scss';
@import './password-strength.scss';

@import './LogoChooser.scss';

@import './rc-switch.scss';

@import './toggle-switch.scss';

@import './_sidebar.scss';

@import './_select.scss';
@import './_navs.scss';
@import './_type.scss';

@font-face {
    font-family: 'Avenir-Book';
    src: url('../fonts/Avenir-Book.eot');
    src: url('../fonts/Avenir-Book.woff2') format('woff2'),
         url('../fonts/Avenir-Book.woff') format('woff'),
         url('../fonts/Avenir-Book.ttf') format('truetype'),
         url('../fonts/Avenir-Book.svg#Avenir-Book') format('svg'),
         url('../fonts/Avenir-Book.eot?#iefix') format('embedded-opentype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Avenir-Heavy';
    src: url('../fonts/Avenir-Heavy.eot');
    src: url('../fonts/Avenir-Heavy.woff2') format('woff2'),
         url('../fonts/Avenir-Heavy.woff') format('woff'),
         url('../fonts/Avenir-Heavy.ttf') format('truetype'),
         url('../fonts/Avenir-Heavy.svg#Avenir-Heavy') format('svg'),
         url('../fonts/Avenir-Heavy.eot?#iefix') format('embedded-opentype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Avenir-Black';
    src: url('../fonts/Avenir-Black.eot');
    src: url('../fonts/Avenir-Black.woff2') format('woff2'),
         url('../fonts/Avenir-Black.woff') format('woff'),
         url('../fonts/Avenir-Black.ttf') format('truetype'),
         url('../fonts/Avenir-Black.svg#Avenir-Black') format('svg'),
         url('../fonts/Avenir-Black.eot?#iefix') format('embedded-opentype');
    font-weight: normal;
    font-style: normal;
  }

body {
    overflow-x: hidden;
}

// Switch to custom font when webfont loader is ready
.wf-active body{
  font-family: "Avenir-Book", "Helvetica Neue", Helvetica, Arial, sans-serif
}

.select-menu-outer{
    z-index:3 !important;
}

*:not(button) > {
    .glyphicon, .material-icons {
    color: $gray-base
  }
}

.mg-bottom {
  margin-bottom: 5px
}

.mg-bottom-lg {
    margin-bottom: 10px
  }
.breadcrumbs {
    flex-grow: 1;
}
.h3, h3 {
    font-size: 24px;
    font-family: "Avenir-Heavy";
    font-weight: normal;
}
.login {
    margin: auto; 
    padding: 20px; 
    width: auto;
}
.login-control {
    margin-top: 0; 
    margin-bottom: 20px;
    margin-right: 0px;
    margin-left: 0px;
    padding: 20px;
}

.g-recaptcha{
  display: block;
  width: 100%;
}

.nav-pills>li.active>a {
    background-color: white;
    border-bottom: solid 2px #2972c6;
    color: #2972c6;
    border-radius: 0px;
    outline: none;
}
.nav-pills>li>a {
    background-color: white;
    color: #231f20;
    border-radius: 0px;
    outline: none !important;
}
.nav-pills>li.active>a:hover {
    background-color: white;
    border-bottom: solid 2px #2972c6;
    color: #2972c6;
    border-radius: 0px;
}
.nav-pills>li>a:focus {
    color: #2972c6 !important;
    background-color: white !important;
    border-bottom: solid 2px rgba(41, 114, 198, 0.55) !important;
}
.nav-pills>li>a:hover {
    color: rgba(41, 114, 198, 0.55) !important;
    background-color: white !important;
    border-bottom: solid 2px rgba(41, 114, 198, 0.55) !important;
}
.nav-pills>li {
    margin-right: 20px;
    font-weight: normal;
}

@keyframes shake {
  0% { transform: translate(0, 0); }
  20% { transform: translate(-10px, 0); }
  40% { transform: translate(10px, 0); }
  60% { transform: translate(-10px, 0); }
  80% { transform: translate(10px, 0); }
  100% { transform: translate(0, 0); }
}

.shake {
  animation: shake 0.5s;
}
