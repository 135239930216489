//
// Navs
// --------------------------------------------------
// Tabs
// -------------------------

// Give the tabs something to sit on
.nav-tabs {
  > li {
    padding-right: 3px;
    // Active state, and its :hover to override normal :hover
    &.active > a {
      &,
      &:hover,
      &:focus {
        background-color: $brand-primary;
        // background-color: $nav-tabs-active-link-hover-bg;
        // border: 1px solid $nav-tabs-active-link-hover-border-color;
        color: #FFF !important;
        box-shadow: none;
        border-bottom-color: transparent;
        cursor: default;
      }
      svg path {
        fill : #FFF;
      }
      .form-control {
        &::-moz-placeholder {
          color: $gray;
        }

        &::-webkit-input-placeholder {
          color: $gray;
        }

        color: #FFF;
        border-color: transparent !important;  
        &:focus {
          color: $input-color;
        }
      }
    }

    > a {
      .form-control {
        border-color: transparent !important;  
        &:focus {
          color: $input-color;
        }

        &::-moz-placeholder {
          color: $gray;
        }

        &::-webkit-input-placeholder {
          color: $gray;
        }

      }
    }
  }
}
